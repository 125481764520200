/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddAnswerRequest } from '../models/AddAnswerRequest';
import type { AddAnswerResponse } from '../models/AddAnswerResponse';
import type { SurveyTypes } from '../models/SurveyTypes';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AnswersService {
	/**
	 * Add an answer to a survey question
	 * POST /clubs/<display id>/surveys/<survey type>/questions/<qid>/answers
	 * @returns AddAnswerResponse Successful Response
	 * @throws ApiError
	 */
	public static addAnswerClubsDisplayIdSurveysSurveyTypeQuestionsQidAnswersPost({
		displayId,
		surveyType,
		qid,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		surveyType: SurveyTypes;
		qid: number;
		requestBody: AddAnswerRequest;
	}): CancelablePromise<AddAnswerResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url:
				'/clubs/{displayId}/surveys/{surveyType}/questions/{qid}/answers',
			path: {
				displayId: displayId,
				surveyType: surveyType,
				qid: qid
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Edit an answer to a survey question
	 * PUT /clubs/<display id>/surveys/<survey type>/questions/<qid>/answers/<aid>
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static editAnswerClubsDisplayIdSurveysSurveyTypeQuestionsQidAnswersAidPut({
		displayId,
		surveyType,
		qid,
		aid,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		surveyType: SurveyTypes;
		qid: number;
		aid: number;
		requestBody: AddAnswerRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'PUT',
			url:
				'/clubs/{displayId}/surveys/{surveyType}/questions/{qid}/answers/{aid}',
			path: {
				displayId: displayId,
				surveyType: surveyType,
				qid: qid,
				aid: aid
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Delete an answer to a survey question
	 * DELETE /clubs/<display id>/surveys/<survey type>/questions/<qid>/answers/<aid>
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static deleteAnswerClubsDisplayIdSurveysSurveyTypeQuestionsQidAnswersAidDelete({
		displayId,
		surveyType,
		qid,
		aid
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		surveyType: SurveyTypes;
		qid: number;
		aid: number;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'DELETE',
			url:
				'/clubs/{displayId}/surveys/{surveyType}/questions/{qid}/answers/{aid}',
			path: {
				displayId: displayId,
				surveyType: surveyType,
				qid: qid,
				aid: aid
			},
			errors: {
				422: `Validation Error`
			}
		});
	}
}
