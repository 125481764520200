/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum SubscriptionIntervalEnum {
	MONTH = 'month',
	YEAR = 'year',
	WEEK = 'week',
	DAY = 'day'
}
