/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckUploadMemberResponse } from '../models/CheckUploadMemberResponse';
import type { ClubsBulkActivateMembersRequest } from '../models/ClubsBulkActivateMembersRequest';
import type { ClubsBulkSuspendMembersRequest } from '../models/ClubsBulkSuspendMembersRequest';
import type { ClubsMembersGetResponse } from '../models/ClubsMembersGetResponse';
import type { ClubsMembersPostRequest } from '../models/ClubsMembersPostRequest';
import type { ClubsMembersPostResponse } from '../models/ClubsMembersPostResponse';
import type { ClubsUpdateMembersRequest } from '../models/ClubsUpdateMembersRequest';
import type { ClubsUpdateSingleMemberRequest } from '../models/ClubsUpdateSingleMemberRequest';
import type { CreateAiChatMessageRequest } from '../models/CreateAiChatMessageRequest';
import type { CreateAiChatMessageResponse } from '../models/CreateAiChatMessageResponse';
import type { GetAiChatMessageResponse } from '../models/GetAiChatMessageResponse';
import type { GetIndividualMemberProfileResponse } from '../models/GetIndividualMemberProfileResponse';
import type { GetMemberRecommendations } from '../models/GetMemberRecommendations';
import type { GroupMemberSortAlgoEnum } from '../models/GroupMemberSortAlgoEnum';
import type { MemberExistenceCheck } from '../models/MemberExistenceCheck';
import type { MembersCountGetResponse } from '../models/MembersCountGetResponse';
import type { MemberSearchRequest } from '../models/MemberSearchRequest';
import type { MemberSearchResponse } from '../models/MemberSearchResponse';
import type { RecommendationResponseModel } from '../models/RecommendationResponseModel';
import type { SearchParametersGetResponse } from '../models/SearchParametersGetResponse';
import type { UploadMemberRequest } from '../models/UploadMemberRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MembersService {
	/**
	 * Get search parameters for this club
	 * GET /clubs/<displayId>/members/search
	 * @returns SearchParametersGetResponse Successful Response
	 * @throws ApiError
	 */
	public static getSearchParametersClubsDisplayIdMembersSearchGet({
		displayId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
	}): CancelablePromise<SearchParametersGetResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}/members/search',
			path: {
				displayId: displayId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Execute a search of members in this club
	 * POST /clubs/<display id>/members/search
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static memberSearchClubsDisplayIdMembersSearchPost({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: MemberSearchRequest;
	}): CancelablePromise<MemberSearchResponse | MemberExistenceCheck> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/clubs/{displayId}/members/search',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Check if this group has an upload in progress
	 * Notifies the client if the group specified by the given display id has a
	 * member upload in progress
	 * @returns CheckUploadMemberResponse Successful Response
	 * @throws ApiError
	 */
	public static checkUploadMembersClubsDisplayIdMembersUploadGet({
		displayId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
	}): CancelablePromise<CheckUploadMemberResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}/members/upload',
			path: {
				displayId: displayId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Upload members to a group
	 * POST /clubs/<display id>/members/upload
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static uploadMembersClubsDisplayIdMembersUploadPost({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: UploadMemberRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/clubs/{displayId}/members/upload',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get matching rounds for a club
	 * Get /clubs/<display id>/members/<member_id>/ai_chat_messages
	 * @returns GetAiChatMessageResponse Successful Response
	 * @throws ApiError
	 */
	public static getAiChatMessagesClubsDisplayIdMembersMemberIdAiChatMessagesGet({
		displayId,
		memberId,
		lastMessageId,
		limit,
		order = 'desc'
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		memberId: number;
		lastMessageId?: string;
		limit?: number;
		order?: 'asc' | 'desc';
	}): CancelablePromise<GetAiChatMessageResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}/members/{member_id}/ai_chat_messages',
			path: {
				displayId: displayId,
				member_id: memberId
			},
			query: {
				lastMessageId: lastMessageId,
				limit: limit,
				order: order
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Create a new AI chat message for a member
	 * POST /clubs/<display id>/members/<member_id>/ai_chat_messages
	 * @returns CreateAiChatMessageResponse Successful Response
	 * @throws ApiError
	 */
	public static createAiChatMessageClubsDisplayIdMembersMemberIdAiChatMessagesPost({
		displayId,
		memberId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		memberId: number;
		requestBody: CreateAiChatMessageRequest;
	}): CancelablePromise<CreateAiChatMessageResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/clubs/{displayId}/members/{member_id}/ai_chat_messages',
			path: {
				displayId: displayId,
				member_id: memberId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get Members for a Group
	 * Load member data from this Group
	 * @returns ClubsMembersGetResponse Successful Response
	 * @throws ApiError
	 */
	public static getMembersClubsDisplayIdMembersGet({
		displayId,
		includeSurvey = false,
		page,
		size = 25,
		active,
		uploaded,
		inviteSent,
		suspended,
		inviteAccepted,
		accepted,
		rejected,
		emailBounced,
		sortAlgo,
		tags,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		includeSurvey?: boolean;
		page?: number;
		size?: number;
		active?: boolean;
		uploaded?: boolean;
		inviteSent?: boolean;
		suspended?: boolean;
		inviteAccepted?: boolean;
		accepted?: boolean;
		rejected?: boolean;
		emailBounced?: boolean;
		sortAlgo?: GroupMemberSortAlgoEnum;
		tags?: string;
		requestBody?: Array<number>;
	}): CancelablePromise<ClubsMembersGetResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}/members',
			path: {
				displayId: displayId
			},
			query: {
				include_survey: includeSurvey,
				page: page,
				size: size,
				active: active,
				uploaded: uploaded,
				inviteSent: inviteSent,
				suspended: suspended,
				inviteAccepted: inviteAccepted,
				accepted: accepted,
				rejected: rejected,
				emailBounced: emailBounced,
				sortAlgo: sortAlgo,
				tags: tags
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Update multiple member in a group
	 * PUT /clubs/{displayId}/members
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static updateMultipleMembersClubsDisplayIdMembersPut({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: ClubsUpdateMembersRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/clubs/{displayId}/members',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Add a member to this group
	 * Add a member to this group
	 * @returns ClubsMembersPostResponse Successful Response
	 * @throws ApiError
	 */
	public static addMemberClubsDisplayIdMembersPost({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: ClubsMembersPostRequest;
	}): CancelablePromise<ClubsMembersPostResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/clubs/{displayId}/members',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get Number of users for a Group
	 * Load number of users from this Group
	 * @returns MembersCountGetResponse Successful Response
	 * @throws ApiError
	 */
	public static getNumberOfUsersByStatusClubsDisplayIdMembersCountGet({
		displayId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
	}): CancelablePromise<MembersCountGetResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}/members/count',
			path: {
				displayId: displayId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get Profile for a Member in a Group
	 * Load profile data for a member in this Group
	 * @returns GetIndividualMemberProfileResponse Successful Response
	 * @throws ApiError
	 */
	public static getMemberProfileClubsDisplayIdMembersMemberIdGet({
		displayId,
		memberId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		memberId: number;
	}): CancelablePromise<GetIndividualMemberProfileResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}/members/{member_id}',
			path: {
				displayId: displayId,
				member_id: memberId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Update a member in a group
	 * Update a member in a group
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static updateMemberClubsDisplayIdMembersMemberIdPut({
		displayId,
		memberId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		memberId: number;
		requestBody: ClubsUpdateSingleMemberRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/clubs/{displayId}/members/{member_id}',
			path: {
				displayId: displayId,
				member_id: memberId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Remove a member from a group
	 * Remove a member from a group
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static removeMemberClubsDisplayIdMembersMemberIdDelete({
		memberId,
		displayId
	}: {
		memberId: number;
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/clubs/{displayId}/members/{member_id}',
			path: {
				member_id: memberId,
				displayId: displayId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get Profile for a Member in a Group
	 * Load profile data for a member in this Group
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static sendMemberInviteClubsDisplayIdMembersMemberIdInvitePost({
		displayId,
		memberId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		memberId: number;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/clubs/{displayId}/members/{member_id}/invite',
			path: {
				displayId: displayId,
				member_id: memberId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get Recommendations for a Member in a Group
	 * GET /clubs/{displayId}/members/<member_id>/recommendations
	 * @returns GetMemberRecommendations Successful Response
	 * @throws ApiError
	 */
	public static getMemberRecommendationsClubsDisplayIdMembersMemberIdRecommendationsGet({
		displayId,
		memberId,
		matchingRoundId,
		page,
		allowRematching = false
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		memberId: number;
		matchingRoundId?: number;
		page?: number;
		allowRematching?: boolean;
	}): CancelablePromise<GetMemberRecommendations> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}/members/{member_id}/recommendations',
			path: {
				displayId: displayId,
				member_id: memberId
			},
			query: {
				matchingRoundId: matchingRoundId,
				page: page,
				allowRematching: allowRematching
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Respond to a recommendations for a Member in a Group
	 * PUT /clubs/{displayId}/members/<member_id>/recommendations
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static respondToRecommendationClubsDisplayIdMembersMemberIdRecommendationsPut({
		displayId,
		memberId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		memberId: number;
		requestBody: RecommendationResponseModel;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/clubs/{displayId}/members/{member_id}/recommendations',
			path: {
				displayId: displayId,
				member_id: memberId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Update multiple member in a group
	 * PUT /clubs/{displayId}/members/bulk-activate-members
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static bulkActivateMembersClubsDisplayIdMembersbulkActivateMembersPut({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: ClubsBulkActivateMembersRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/clubs/{displayId}/membersbulk-activate-members',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Suspend multiple member in a group
	 * PUT /clubs/{displayId}/members/bulk-suspend-members
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static bulkSuspendMembersClubsDisplayIdMembersbulkSuspendMembersPut({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: ClubsBulkSuspendMembersRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/clubs/{displayId}/membersbulk-suspend-members',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}
}
