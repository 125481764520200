/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PortalFlow {
	UPDATE_PAYMENT = 'update_payment',
	UPDATE_PLAN = 'update_plan'
}
