/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum StripePriceLookupKeyEnum {
	STARTER_MONTHLY = 'starter_monthly',
	STARTER_YEARLY = 'starter_yearly',
	PRO_MONTHLY = 'pro_monthly',
	PRO_YEARLY = 'pro_yearly'
}
