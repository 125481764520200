/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddAnswerRequest } from '../models/AddAnswerRequest';
import type { AddAnswerResponse } from '../models/AddAnswerResponse';
import type { ConditionalLogicModel } from '../models/ConditionalLogicModel';
import type { CreateConditionalLogicRequest } from '../models/CreateConditionalLogicRequest';
import type { CreateMatchingLogicRequest } from '../models/CreateMatchingLogicRequest';
import type { CreateQuestionPostRequest } from '../models/CreateQuestionPostRequest';
import type { CreateQuestionPostResponse } from '../models/CreateQuestionPostResponse';
import type { CreateQuestionsBulkPostRequest } from '../models/CreateQuestionsBulkPostRequest';
import type { FixedSlidesEnum } from '../models/FixedSlidesEnum';
import type { GetConditionalLogicResponse } from '../models/GetConditionalLogicResponse';
import type { SurveyModel } from '../models/SurveyModel';
import type { SurveyTypes } from '../models/SurveyTypes';
import type { UpdateConditionalLogicRequest } from '../models/UpdateConditionalLogicRequest';
import type { UpdateMatchingLogicRequest } from '../models/UpdateMatchingLogicRequest';
import type { UpdateMatchingLogicResponse } from '../models/UpdateMatchingLogicResponse';
import type { UpdateQuestionPutRequest } from '../models/UpdateQuestionPutRequest';
import type { UpdateQuestionPutResponse } from '../models/UpdateQuestionPutResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProfilesFeedbackService {
	/**
	 * Add an answer to a survey question
	 * POST /clubs/<display id>/surveys/<survey type>/questions/<qid>/answers
	 * @returns AddAnswerResponse Successful Response
	 * @throws ApiError
	 */
	public static addAnswerClubsDisplayIdSurveysSurveyTypeQuestionsQidAnswersPost({
		displayId,
		surveyType,
		qid,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		surveyType: SurveyTypes;
		qid: number;
		requestBody: AddAnswerRequest;
	}): CancelablePromise<AddAnswerResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url:
				'/clubs/{displayId}/surveys/{surveyType}/questions/{qid}/answers',
			path: {
				displayId: displayId,
				surveyType: surveyType,
				qid: qid
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Edit an answer to a survey question
	 * PUT /clubs/<display id>/surveys/<survey type>/questions/<qid>/answers/<aid>
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static editAnswerClubsDisplayIdSurveysSurveyTypeQuestionsQidAnswersAidPut({
		displayId,
		surveyType,
		qid,
		aid,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		surveyType: SurveyTypes;
		qid: number;
		aid: number;
		requestBody: AddAnswerRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'PUT',
			url:
				'/clubs/{displayId}/surveys/{surveyType}/questions/{qid}/answers/{aid}',
			path: {
				displayId: displayId,
				surveyType: surveyType,
				qid: qid,
				aid: aid
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Delete an answer to a survey question
	 * DELETE /clubs/<display id>/surveys/<survey type>/questions/<qid>/answers/<aid>
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static deleteAnswerClubsDisplayIdSurveysSurveyTypeQuestionsQidAnswersAidDelete({
		displayId,
		surveyType,
		qid,
		aid
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		surveyType: SurveyTypes;
		qid: number;
		aid: number;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'DELETE',
			url:
				'/clubs/{displayId}/surveys/{surveyType}/questions/{qid}/answers/{aid}',
			path: {
				displayId: displayId,
				surveyType: surveyType,
				qid: qid,
				aid: aid
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get the conditional logic for a question
	 * GET /clubs/<display id>/surveys/<survey type>/questions/<qid>/conditional-logic
	 * @returns GetConditionalLogicResponse Successful Response
	 * @throws ApiError
	 */
	public static getConditionalLogicClubsDisplayIdSurveysSurveyTypeQuestionsQidConditionalLogicGet({
		displayId,
		surveyType,
		qid,
		active,
		aid,
		target
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		surveyType: SurveyTypes;
		qid: number;
		active?: boolean;
		aid?: number;
		target?: number;
	}): CancelablePromise<GetConditionalLogicResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url:
				'/clubs/{displayId}/surveys/{surveyType}/questions/{qid}/conditional-logic',
			path: {
				displayId: displayId,
				surveyType: surveyType,
				qid: qid
			},
			query: {
				active: active,
				aid: aid,
				target: target
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Create a conditional logic rule for a question
	 * POST /clubs/<display id>/surveys/<survey type>/questions/<qid>/conditional-logic
	 * @returns ConditionalLogicModel Successful Response
	 * @throws ApiError
	 */
	public static createConditionalLogicClubsDisplayIdSurveysSurveyTypeQuestionsQidConditionalLogicPost({
		displayId,
		surveyType,
		qid,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		surveyType: SurveyTypes;
		qid: number;
		requestBody: CreateConditionalLogicRequest;
	}): CancelablePromise<ConditionalLogicModel> {
		return __request(OpenAPI, {
			method: 'POST',
			url:
				'/clubs/{displayId}/surveys/{surveyType}/questions/{qid}/conditional-logic',
			path: {
				displayId: displayId,
				surveyType: surveyType,
				qid: qid
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Update a conditional logic rule for a question
	 * PUT /clubs/<display id>/surveys/<survey type>/questions/<qid>/conditional-logic/<cid>
	 * @returns ConditionalLogicModel Successful Response
	 * @throws ApiError
	 */
	public static updateConditionalLogicClubsDisplayIdSurveysSurveyTypeQuestionsQidConditionalLogicCidPut({
		displayId,
		surveyType,
		qid,
		cid,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		surveyType: SurveyTypes;
		qid: number;
		cid: number;
		requestBody: UpdateConditionalLogicRequest;
	}): CancelablePromise<ConditionalLogicModel> {
		return __request(OpenAPI, {
			method: 'PUT',
			url:
				'/clubs/{displayId}/surveys/{surveyType}/questions/{qid}/conditional-logic/{cid}',
			path: {
				displayId: displayId,
				surveyType: surveyType,
				qid: qid,
				cid: cid
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Update matching logic to the question specified
	 * PUT /clubs/<display id>/surveys/<survey type>/questions/<qid>/matching
	 * @returns UpdateMatchingLogicResponse Successful Response
	 * @throws ApiError
	 */
	public static updateMatchingLogicClubsDisplayIdSurveysSurveyTypeQuestionsQidMatchingPut({
		displayId,
		surveyType,
		qid,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		surveyType: SurveyTypes;
		qid: number;
		requestBody: UpdateMatchingLogicRequest;
	}): CancelablePromise<UpdateMatchingLogicResponse> {
		return __request(OpenAPI, {
			method: 'PUT',
			url:
				'/clubs/{displayId}/surveys/{surveyType}/questions/{qid}/matching',
			path: {
				displayId: displayId,
				surveyType: surveyType,
				qid: qid
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Apply matching logic to the question specified
	 * POST /clubs/<display id>/surveys/<survey type>/questions/<qid>/matching
	 * @returns UpdateMatchingLogicResponse Successful Response
	 * @throws ApiError
	 */
	public static applyMatchingLogicClubsDisplayIdSurveysSurveyTypeQuestionsQidMatchingPost({
		displayId,
		surveyType,
		qid,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		surveyType: SurveyTypes;
		qid: number;
		requestBody: CreateMatchingLogicRequest;
	}): CancelablePromise<UpdateMatchingLogicResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url:
				'/clubs/{displayId}/surveys/{surveyType}/questions/{qid}/matching',
			path: {
				displayId: displayId,
				surveyType: surveyType,
				qid: qid
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Add a question to the Profile or Feedback survey
	 * POST /clubs/<display id>/surveys/<survey type>/questions
	 * @returns CreateQuestionPostResponse Successful Response
	 * @throws ApiError
	 */
	public static createQuestionClubsDisplayIdSurveysSurveyTypeQuestionsPost({
		displayId,
		surveyType,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		surveyType: SurveyTypes;
		requestBody: CreateQuestionPostRequest;
	}): CancelablePromise<CreateQuestionPostResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/clubs/{displayId}/surveys/{surveyType}/questions',
			path: {
				displayId: displayId,
				surveyType: surveyType
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Add one or more template questions to a survey.
	 * POST /clubs/<display id>/surveys/<survey type>/questions
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static addTemplateQuestionsClubsDisplayIdSurveysSurveyTypeQuestionsBulkPost({
		displayId,
		surveyType,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		surveyType: SurveyTypes;
		requestBody: CreateQuestionsBulkPostRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/clubs/{displayId}/surveys/{surveyType}/questions/bulk',
			path: {
				displayId: displayId,
				surveyType: surveyType
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Update a question in the Profile or Feedback survey
	 * PUT /clubs/<display id>/surveys/<survey type>/questions/<qid>
	 * @returns UpdateQuestionPutResponse Successful Response
	 * @throws ApiError
	 */
	public static updateQuestionClubsDisplayIdSurveysSurveyTypeQuestionsQidPut({
		displayId,
		surveyType,
		qid,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		surveyType: SurveyTypes;
		qid: number | FixedSlidesEnum;
		requestBody: UpdateQuestionPutRequest;
	}): CancelablePromise<UpdateQuestionPutResponse> {
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/clubs/{displayId}/surveys/{surveyType}/questions/{qid}',
			path: {
				displayId: displayId,
				surveyType: surveyType,
				qid: qid
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Delete a question from the Profile or Feedback survey
	 * DELETE /clubs/<display id>/surveys/<survey type>/questions/<qid>
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static deleteQuestionClubsDisplayIdSurveysSurveyTypeQuestionsQidDelete({
		displayId,
		surveyType,
		qid
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		surveyType: SurveyTypes;
		qid: number;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/clubs/{displayId}/surveys/{surveyType}/questions/{qid}',
			path: {
				displayId: displayId,
				surveyType: surveyType,
				qid: qid
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get a survey for a club
	 * GET /clubs/<display id>/surveys/<surveyType>
	 * @returns SurveyModel Successful Response
	 * @throws ApiError
	 */
	public static getSurveyClubsDisplayIdSurveysSurveyTypeGet({
		displayId,
		surveyType,
		activeOnly = false
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		surveyType: SurveyTypes;
		activeOnly?: boolean;
	}): CancelablePromise<SurveyModel> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}/surveys/{surveyType}',
			path: {
				displayId: displayId,
				surveyType: surveyType
			},
			query: {
				activeOnly: activeOnly
			},
			errors: {
				422: `Validation Error`
			}
		});
	}
}
