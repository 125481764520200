/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum MatchingRoundTypeEnum {
	OPT_IN = 'opt-in',
	OPT_OUT = 'opt-out'
}
