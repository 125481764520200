/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminSyncTagsRequest } from '../models/AdminSyncTagsRequest';
import type { CommunicationTypeEnum } from '../models/CommunicationTypeEnum';
import type { CreateRestHookPostRequest } from '../models/CreateRestHookPostRequest';
import type { CreateRestHookResponse } from '../models/CreateRestHookResponse';
import type { CriticalWorkflowEnum } from '../models/CriticalWorkflowEnum';
import type { DeleteRestHookRequest } from '../models/DeleteRestHookRequest';
import type { DiscordAddPostRequest } from '../models/DiscordAddPostRequest';
import type { DiscordKickoffPostRequest } from '../models/DiscordKickoffPostRequest';
import type { GenerateGPtIntroRequest } from '../models/GenerateGPtIntroRequest';
import type { GenerateGPtIntroResponse } from '../models/GenerateGPtIntroResponse';
import type { GetDiscordOauthResponse } from '../models/GetDiscordOauthResponse';
import type { InternalClubAddUserPostRequest } from '../models/InternalClubAddUserPostRequest';
import type { InternalClubGetResponse } from '../models/InternalClubGetResponse';
import type { InternalClubPostRequest } from '../models/InternalClubPostRequest';
import type { InternalClubPostResponse } from '../models/InternalClubPostResponse';
import type { InternalClubsResetEmailTemplatePostRequest } from '../models/InternalClubsResetEmailTemplatePostRequest';
import type { InternalClubsTestGenerateGPTIntroMapPostRequest } from '../models/InternalClubsTestGenerateGPTIntroMapPostRequest';
import type { InternalClubsWorflowsInviteRoundPostRequest } from '../models/InternalClubsWorflowsInviteRoundPostRequest';
import type { InternalClubsWorflowsMatchingRoundPostRequest } from '../models/InternalClubsWorflowsMatchingRoundPostRequest';
import type { InternalClubsWorkflowPostRequest } from '../models/InternalClubsWorkflowPostRequest';
import type { InternalClubsWorkflowsMatchPostRequest } from '../models/InternalClubsWorkflowsMatchPostRequest';
import type { InternalEmulatAdminPostResponse } from '../models/InternalEmulatAdminPostResponse';
import type { InternalEmulateAdminPostRequest } from '../models/InternalEmulateAdminPostRequest';
import type { InternalLoadMemberProfilesPostRequest } from '../models/InternalLoadMemberProfilesPostRequest';
import type { InternalLoadMemberProfilesPostResponse } from '../models/InternalLoadMemberProfilesPostResponse';
import type { InternalUserSearchRequest } from '../models/InternalUserSearchRequest';
import type { InternalWorkflowsPostRequest } from '../models/InternalWorkflowsPostRequest';
import type { PostMarkBounceRequest } from '../models/PostMarkBounceRequest';
import type { PreSignedUrlRequest } from '../models/PreSignedUrlRequest';
import type { QuestionBankEntry } from '../models/QuestionBankEntry';
import type { SlackKickoffPostRequest } from '../models/SlackKickoffPostRequest';
import type { SlackKickoffPostResponse } from '../models/SlackKickoffPostResponse';
import type { SystemMonitorGetResponse } from '../models/SystemMonitorGetResponse';
import type { TriggerCriticalFlowRequest } from '../models/TriggerCriticalFlowRequest';
import type { WorkflowEnum } from '../models/WorkflowEnum';
import type { ZapierMembersDeleteRequest } from '../models/ZapierMembersDeleteRequest';
import type { ZapierMembersPostRequest } from '../models/ZapierMembersPostRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InternalService {
	/**
	 * Execute a Matching Round
	 * Used by our testing dashboard to kick off single matching rounds for test groups
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static kickOffEventInternalClubsDisplayIdWorkflowsExecuteMatchingRoundPost({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: InternalClubsWorflowsMatchingRoundPostRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/clubs/{displayId}/workflows/execute-matching-round',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Execute an Invite Round
	 * Used by our testing dashboard to kick off single invite rounds for test groups
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static kickOffOptInsInternalClubsDisplayIdWorkflowsExecuteInviteRoundPost({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: InternalClubsWorflowsInviteRoundPostRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/clubs/{displayId}/workflows/execute-invite-round',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Force a Match between two test users
	 * Used by our testing dashboard to force a match between members of a test group
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static forceMatchInternalClubsDisplayIdWorkflowsForceMatchPost({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: InternalClubsWorkflowsMatchPostRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/clubs/{displayId}/workflows/force-match',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Send Reviews for Test Group
	 * Used by our testing dashboard to send reviews for a test group
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static sendReviewsInternalClubsDisplayIdWorkflowsSendReviewsPost({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: InternalClubsWorkflowPostRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/clubs/{displayId}/workflows/send-reviews',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Generate a mapping of all GPT intros for this group
	 * Create an opt out round for this group, then immediately generate intros for all members of this group
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static generateGptIntroMapInternalClubsDisplayIdTestGenerateGptIntroMapPost({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: InternalClubsTestGenerateGPTIntroMapPostRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/clubs/{displayId}/test/generate-gpt-intro-map',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Reset Email Template
	 * Used by our testing dashboard to reset the email template for a test group to the value in unlayer
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static resetEmailTemplateInternalClubsDisplayIdResetEmailTemplatePost({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: InternalClubsResetEmailTemplatePostRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/clubs/{displayId}/resetEmailTemplate/',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Create a Test Club
	 * Used from our Internal Testing page to create test clubs on Intros
	 * @returns InternalClubPostResponse Successful Response
	 * @throws ApiError
	 */
	public static createTestClubInternalClubsPost({
		requestBody
	}: {
		requestBody: InternalClubPostRequest;
	}): CancelablePromise<InternalClubPostResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/clubs/',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Load a Test Club
	 * Used from our Internal Testing page to load test clubs on Intros
	 * @returns InternalClubGetResponse Successful Response
	 * @throws ApiError
	 */
	public static loadTestClubInternalClubsDisplayIdGet({
		displayId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
	}): CancelablePromise<InternalClubGetResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/internal/clubs/{displayId}',
			path: {
				displayId: displayId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Add one or many users to a Test Intros Club
	 * Used from our Internal Testing page to add users to a test club on Intros
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static addUsersInternalClubsDisplayIdAddUserPost({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: InternalClubAddUserPostRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/clubs/{displayId}/add-user',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Sync tags for this Club
	 * POST /internal/clubs/<display id>/sync-tags
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static syncTagsInternalClubsDisplayIdSyncTagsPost({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: AdminSyncTagsRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/clubs/{displayId}/sync-tags',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Kick off a workflow
	 * Used to kick off a workflow throughout the Intros ecosystem
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static kickOffWorkflowInternalWorkflowsWorkflowPost({
		workflow,
		requestBody
	}: {
		/**
		 * The workflow to kick off via this endpoint
		 */
		workflow: WorkflowEnum;
		requestBody: InternalWorkflowsPostRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/workflows/{workflow}',
			path: {
				workflow: workflow
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Trigger scheduled Intros or Invite flows for all groups over the given channel
	 * POST /internal/flows/{flow type}/{channel}
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static triggerCriticalWorkflowInternalFlowsFlowChannelPost({
		flow,
		channel,
		requestBody
	}: {
		flow: CriticalWorkflowEnum;
		channel: CommunicationTypeEnum;
		requestBody: TriggerCriticalFlowRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/flows/{flow}/{channel}',
			path: {
				flow: flow,
				channel: channel
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Readiness Probe
	 * Check if the server is ready to serve requests
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static readyInternalProbesReadyGet(): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/internal/probes/ready'
		});
	}

	/**
	 * Pre-Stop Probe
	 * Mark the server for beginning shutdown
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static preStopInternalProbesPreStopGet({
		request
	}: {
		request: any;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/internal/probes/pre-stop',
			query: {
				request: request
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Create a REST hook record
	 * POST /internal/integrations/rest-hooks
	 * @returns CreateRestHookResponse Successful Response
	 * @throws ApiError
	 */
	public static createRestHookInternalIntegrationsRestHooksPost({
		requestBody
	}: {
		requestBody: CreateRestHookPostRequest;
	}): CancelablePromise<CreateRestHookResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/integrations/rest-hooks',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Delete a REST hook record
	 * DELETE /internal/integrations/rest-hooks
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static deleteRestHookInternalIntegrationsRestHooksDelete({
		requestBody
	}: {
		requestBody: DeleteRestHookRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/internal/integrations/rest-hooks',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Kickoff Slack Matching
	 * POST /internal/integrations/webhooks/slack/kickoff
	 * @returns SlackKickoffPostResponse Successful Response
	 * @throws ApiError
	 */
	public static kickoffInternalIntegrationsWebhooksSlackKickoffPost({
		requestBody
	}: {
		requestBody: SlackKickoffPostRequest;
	}): CancelablePromise<SlackKickoffPostResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/integrations/webhooks/slack/kickoff',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get OAuth URL to authenticate and allow Intros Bot to join a Discord Server
	 * GET /internal/integrations/webhooks/discord/oauth
	 * @returns GetDiscordOauthResponse Successful Response
	 * @throws ApiError
	 */
	public static getOauthUrlInternalIntegrationsWebhooksDiscordOauthGet({
		displayId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
	}): CancelablePromise<GetDiscordOauthResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/internal/integrations/webhooks/discord/oauth',
			query: {
				displayId: displayId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Add Discord Server to Intros Club
	 * POST /internal/integrations/webhooks/discord/add
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static addDiscordServerInternalIntegrationsWebhooksDiscordAddPost({
		requestBody
	}: {
		requestBody: DiscordAddPostRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/integrations/webhooks/discord/add',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Kickoff Intros in the Discord Server
	 * POST /internal/integrations/webhooks/discord/kickoff
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static kickoffDiscordServerInternalIntegrationsWebhooksDiscordKickoffPost({
		requestBody
	}: {
		requestBody: DiscordKickoffPostRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/integrations/webhooks/discord/kickoff',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get all groups for an admin
	 * GET /internal/integrations/webhooks/zapier/groups
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static getZapierGroupsInternalIntegrationsWebhooksZapierGroupsGet(): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/internal/integrations/webhooks/zapier/groups'
		});
	}

	/**
	 * Get all questions for a group
	 * GET /internal/integrations/webhooks/zapier/groups/{displayId}/questions
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static getZapierQuestionsInternalIntegrationsWebhooksZapierGroupsDisplayIdQuestionsGet({
		displayId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'GET',
			url:
				'/internal/integrations/webhooks/zapier/groups/{displayId}/questions',
			path: {
				displayId: displayId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get all members for a group
	 * GET /internal/integrations/webhooks/zapier/groups/{displayId}/members
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static getZapierMembersInternalIntegrationsWebhooksZapierGroupsDisplayIdMembersGet({
		displayId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'GET',
			url:
				'/internal/integrations/webhooks/zapier/groups/{displayId}/members',
			path: {
				displayId: displayId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Update a group member
	 * PUT /internal/integrations/zapier/groups/{displayId}/members
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static updateZapierMemberInternalIntegrationsWebhooksZapierGroupsDisplayIdMembersPut({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: ZapierMembersPostRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'PUT',
			url:
				'/internal/integrations/webhooks/zapier/groups/{displayId}/members',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Add a member to this group via Zapier
	 * POST /internal/integrations/webhooks/zapier/groups/{displayId}/members
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static postZapierMemberInternalIntegrationsWebhooksZapierGroupsDisplayIdMembersPost({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: ZapierMembersPostRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url:
				'/internal/integrations/webhooks/zapier/groups/{displayId}/members',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Delete a group member
	 * DELETE /internal/integrations/zapier/groups/{displayId}/members
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static deleteZapierMemberInternalIntegrationsWebhooksZapierGroupsDisplayIdMembersDelete({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: ZapierMembersDeleteRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'DELETE',
			url:
				'/internal/integrations/webhooks/zapier/groups/{displayId}/members',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Generates a Presigned Url for S3 upload
	 * POST /internal/integrations/webhooks/s3/generate-presigned-url
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static generatePresignedUrlInternalIntegrationsWebhooksS3GeneratePresignedUrlPost({
		requestBody
	}: {
		requestBody: PreSignedUrlRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/integrations/webhooks/s3/generate-presigned-url',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Update feature access from Salesbricks request
	 * POST /internal/integrations/webhooks/salesbricks
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static salesbricksWebhookInternalIntegrationsWebhooksSalesbricksPost({
		request
	}: {
		request: any;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/integrations/webhooks/salesbricks',
			query: {
				request: request
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * PostMark webhook to receive and handle bounces
	 * POST /internal/integrations/webhooks/postmark/bounce
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static recordPostmarkBounceInternalIntegrationsWebhooksPostmarkBouncePost({
		requestBody
	}: {
		requestBody: PostMarkBounceRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/integrations/webhooks/postmark/bounce',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Update feature access from Salesbricks request
	 * POST /internal/integrations/webhooks/salesbricks
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static stripeWebhookInternalIntegrationsWebhooksStripePost(): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/integrations/webhooks/stripe'
		});
	}

	/**
	 * Get all questions in the question bank
	 * GET /internal/question-bank
	 * @returns QuestionBankEntry Successful Response
	 * @throws ApiError
	 */
	public static getQuestionBankInternalQuestionBankGet(): CancelablePromise<
		Array<QuestionBankEntry>
	> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/internal/question-bank'
		});
	}

	/**
	 * Search for users by email
	 * POST /internal/users/search
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static searchUsersInternalUsersSearchPost({
		requestBody
	}: {
		requestBody: InternalUserSearchRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/users/search',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Generate a GPT intro for a match
	 * POST /internal/matches/{mid}/gnerateGptIntro
	 * @returns GenerateGPtIntroResponse Successful Response
	 * @throws ApiError
	 */
	public static generateGptIntroInternalMatchesMidGenerateGptIntroPost({
		mid,
		requestBody
	}: {
		mid: number;
		requestBody: GenerateGPtIntroRequest;
	}): CancelablePromise<GenerateGPtIntroResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/matches/{mid}/generateGptIntro',
			path: {
				mid: mid
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * System Monitor
	 * Get System Events Over a Timeframe
	 * @returns SystemMonitorGetResponse Successful Response
	 * @throws ApiError
	 */
	public static getSystemMonitorEventsInternalMonitorGet({
		start,
		end
	}: {
		start: string;
		end: string;
	}): CancelablePromise<SystemMonitorGetResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/internal/monitor',
			query: {
				start: start,
				end: end
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * System Monitor Event Resolution
	 * Resolve a System Event
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static resolveSystemEventInternalMonitorIdResolvePost({
		id
	}: {
		id: number;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/monitor/{id}/resolve',
			path: {
				id: id
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Emulate an Admin's Intros Dashboard
	 * This should be used from our Internal dashboard to request emulation of an admins profile and provide support for their group
	 * @returns InternalEmulatAdminPostResponse Successful Response
	 * @throws ApiError
	 */
	public static loadAdminUserInternalEmulateAdminPost({
		requestBody
	}: {
		requestBody: InternalEmulateAdminPostRequest;
	}): CancelablePromise<InternalEmulatAdminPostResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/emulate-admin',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Load a Member's Club Profiles.
	 * This should be used from our Internal dashboard to load all profile links associated with a member's email address
	 * @returns InternalLoadMemberProfilesPostResponse Successful Response
	 * @throws ApiError
	 */
	public static loadMemberProfilesInternalLoadMemberProfilesPost({
		requestBody
	}: {
		requestBody: InternalLoadMemberProfilesPostRequest;
	}): CancelablePromise<InternalLoadMemberProfilesPostResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/load-member-profiles',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Sync PostMark bounces with the database
	 * This should be used only to sync bounces from PostMark with the database
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static syncBouncedEmailsInternalSyncBouncedEmailsPost(): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/sync-bounced-emails'
		});
	}
}
