/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginRequest } from '../models/LoginRequest';
import type { MagicLinkRequest } from '../models/MagicLinkRequest';
import type { OAuth2UrlResponse } from '../models/OAuth2UrlResponse';
import type { SaveOAuthCredentialsRequest } from '../models/SaveOAuthCredentialsRequest';
import type { UserLiteData } from '../models/UserLiteData';
import type { UserLoginResponse } from '../models/UserLoginResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {
	/**
	 * Login as an admin
	 * POST /auth/login
	 * @returns UserLoginResponse Successful Response
	 * @throws ApiError
	 */
	public static loginAuthLoginPost({
		requestBody,
		auth0Verified
	}: {
		requestBody: LoginRequest;
		auth0Verified?: string;
	}): CancelablePromise<UserLoginResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/auth/login',
			query: {
				auth0Verified: auth0Verified
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Send a profile Magic Link email
	 * POST /auth/magic-link
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static sendMagicLinkAuthMagicLinkPost({
		requestBody
	}: {
		requestBody: MagicLinkRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/auth/magic-link',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get information on the current user with an auth token
	 * GET /users/auth
	 * @returns UserLiteData Successful Response
	 * @throws ApiError
	 */
	public static authUserUsersAuthGet(): CancelablePromise<UserLiteData> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/users/auth'
		});
	}

	/**
	 * Get outlook OAuth Url
	 * GET /users/auth/outlook
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static getOutlookOauthUrlUsersAuthOutlookGet(): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/users/auth/outlook'
		});
	}

	/**
	 * Save credentials for a users Outlook access
	 * POST /users/auth/outlook
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static saveOutlookCredentialsUsersAuthOutlookPost({
		requestBody
	}: {
		requestBody: SaveOAuthCredentialsRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/users/auth/outlook',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get google OAuth Url
	 * GET /users/auth/google
	 * @returns OAuth2UrlResponse Successful Response
	 * @throws ApiError
	 */
	public static getGoogleOauthUrlUsersAuthGoogleGet({
		redirectUri,
		state
	}: {
		redirectUri: string;
		state: string;
	}): CancelablePromise<OAuth2UrlResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/users/auth/google',
			query: {
				redirectURI: redirectUri,
				state: state
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Save credentials for a users Google access
	 * POST /users/auth/google
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static saveGoogleCredentialsUsersAuthGooglePost({
		requestBody
	}: {
		requestBody: SaveOAuthCredentialsRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/users/auth/google',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}
}
