/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClubsAccessGetResponse } from '../models/ClubsAccessGetResponse';
import type { ClubsAccessUpdateRequest } from '../models/ClubsAccessUpdateRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FeatureAccessService {
	/**
	 * Load an Intros Club's Feature Access.
	 * Used to load an Intros Club's Feature Access via the API
	 * @returns ClubsAccessGetResponse Successful Response
	 * @throws ApiError
	 */
	public static getClubAccessClubsAccessDisplayIdGet({
		displayId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
	}): CancelablePromise<ClubsAccessGetResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/access/{displayId}',
			path: {
				displayId: displayId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Update an Intros Club's Feature Access
	 * Used to update an Intros Club's Feature Access via the API
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static updateClubAccssClubsAccessDisplayIdPut({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: ClubsAccessUpdateRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/clubs/access/{displayId}',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get user feature access information
	 * GET /users/<uid>/access/<feature>
	 * @returns boolean Successful Response
	 * @throws ApiError
	 */
	public static checkUserFeatureAccessUsersUidAccessFeatureGet({
		uid,
		feature
	}: {
		uid: number;
		feature: string;
	}): CancelablePromise<boolean> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/users/{uid}/access/{feature}',
			path: {
				uid: uid,
				feature: feature
			},
			errors: {
				422: `Validation Error`
			}
		});
	}
}
