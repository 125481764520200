/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClubsUsersGetResponse } from '../models/ClubsUsersGetResponse';
import type { CreateUserModel } from '../models/CreateUserModel';
import type { InternalUserSearchRequest } from '../models/InternalUserSearchRequest';
import type { ResetVerificationPostRequest } from '../models/ResetVerificationPostRequest';
import type { ResetVerificationPostResponse } from '../models/ResetVerificationPostResponse';
import type { SendVerificationPostRequest } from '../models/SendVerificationPostRequest';
import type { SendVerificationPostResponse } from '../models/SendVerificationPostResponse';
import type { UpdateUserRequest } from '../models/UpdateUserRequest';
import type { UserLoginResponse } from '../models/UserLoginResponse';
import type { UserMemberships } from '../models/UserMemberships';
import type { UserProfileModel } from '../models/UserProfileModel';
import type { VerifyEmailPostRequest } from '../models/VerifyEmailPostRequest';
import type { VerifyEmailPostResponse } from '../models/VerifyEmailPostResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {
	/**
	 * Get Members for a Group
	 * Load member data from this Group
	 * @returns ClubsUsersGetResponse Successful Response
	 * @throws ApiError
	 */
	public static getUserLiteDataClubsDisplayIdUsersGet({
		displayId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
	}): CancelablePromise<ClubsUsersGetResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}/users',
			path: {
				displayId: displayId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Search for users by email
	 * POST /internal/users/search
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static searchUsersInternalUsersSearchPost({
		requestBody
	}: {
		requestBody: InternalUserSearchRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/users/search',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get memberships for the user specified with the passed cookie
	 * GET /users/memberships
	 * @returns UserMemberships Successful Response
	 * @throws ApiError
	 */
	public static getMembershipsUsersMembershipsGet(): CancelablePromise<UserMemberships> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/users/memberships',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Send a verification email to a new email that a user wants to verify
	 * Used to initiate the process of a user changing their email.
	 * @returns SendVerificationPostResponse Successful Response
	 * @throws ApiError
	 */
	public static sendVerificationEmailUsersEmailVerificationSendPost({
		requestBody
	}: {
		requestBody: SendVerificationPostRequest;
	}): CancelablePromise<SendVerificationPostResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/users/email-verification/send',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Clear out any existing values for unverifiedEmail or unverifiedEmailHash
	 * Used to reset the process of a user changing their email.
	 * @returns ResetVerificationPostResponse Successful Response
	 * @throws ApiError
	 */
	public static resetVerificationEmailUsersEmailVerificationResetPost({
		requestBody
	}: {
		requestBody: ResetVerificationPostRequest;
	}): CancelablePromise<ResetVerificationPostResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/users/email-verification/reset',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Verify a user's unverifiedEmail with their unverifiedEmailHash
	 * Used to finalize the process of a user changing their email by replacing their email with their unverifiedEmail.
	 * @returns VerifyEmailPostResponse Successful Response
	 * @throws ApiError
	 */
	public static verifyEmailUsersEmailVerificationVerifyPost({
		requestBody
	}: {
		requestBody: VerifyEmailPostRequest;
	}): CancelablePromise<VerifyEmailPostResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/users/email-verification/verify',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get user lite info from query parameters
	 * GET /users
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static getUserInfoUsersGet({
		email
	}: {
		email: string;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/users',
			query: {
				email: email
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Create a new admin
	 * POST /users
	 * @returns UserLoginResponse Successful Response
	 * @throws ApiError
	 */
	public static createAdminUsersPost({
		requestBody
	}: {
		requestBody: CreateUserModel;
	}): CancelablePromise<UserLoginResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/users',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get user info from the passed cookie
	 * @returns UserProfileModel Successful Response
	 * @throws ApiError
	 */
	public static getUserInfoFromCookieUsersSelfGet(): CancelablePromise<UserProfileModel> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/users/self',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get user profile
	 * GET /users/{uid}
	 * @returns UserProfileModel Successful Response
	 * @throws ApiError
	 */
	public static getUserProfileUsersUidGet({
		uid
	}: {
		uid: string;
	}): CancelablePromise<UserProfileModel> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/users/{uid}',
			path: {
				uid: uid
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Update user info
	 * PUT /users/{uid}
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static updateUserInfoUsersUidPut({
		uid,
		requestBody
	}: {
		uid: number;
		requestBody: UpdateUserRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/users/{uid}',
			path: {
				uid: uid
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}
}
