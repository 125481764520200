/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum SearchTypeEnum {
	SEMANTIC = 'semantic',
	KEYWORD = 'keyword',
	MEMBERSHIP = 'membership'
}
