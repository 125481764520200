/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum MatchingRuleEnum {
	MUST_MATCH = 'Must Match',
	CAN_NOT_MATCH = 'Can Not Match',
	SELF_MATCHES = 'Self Matches',
	PREFERS = 'Prefers'
}
