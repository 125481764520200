import moment from 'moment';
import tz from 'moment-timezone';
import { optInIntroDays } from '../components/flow/settings/types';

/**
 * Helper function that accepts a date of an event match and formats the time to display on the UI
 */
export function formatTimeForEvent(
	time: Date,
	displayTimeZone: boolean = false,
	timezone?: string
) {
	// get time string
	const timeString = time.toLocaleTimeString();

	// get zone name
	const zoneNameValue = displayTimeZone ? ` ${formatTimezone(timezone)}` : '';

	// get am/pm
	const amOrPm = timeString.split(' ')[1]?.toLowerCase();
	const withoutSeconds = timeString.split(':').slice(0, 2).join(':');
	// return formatted time
	return `${withoutSeconds}${amOrPm || ''}${zoneNameValue}`;
}

/**
 * Helper function that converts a day of the week index to a human-readable name (0=sunday)
 */
export function formatDay(dayIndex: number) {
	return optInIntroDays[dayIndex || 0];
}

/**
 * Helper function to convert a long-form timezone to the abbreviation
 */
export function formatTimezone(timezone: string) {
	return moment.tz(timezone).zoneAbbr();
}

/**
 * Helper function that determines the date of the target day (the number 0-6 that represents
 * the next occurance of that day of the week). For example, if today is Wednesday, then the next
 * tuesday would be next week's tuesday. It will return the formatted version of that day.
 */
export function getFormattedFutureDay(
	targetDay: number,
	time: string,
	timezone: string,
	nextWeek: boolean = false
) {
	if (targetDay === undefined || !time || !timezone) {
		return;
	}
	const todayWeekday = moment().isoWeekday();
	// NOTE: passing true as a parameter maintains the local time
	const targetTime = moment(time, 'HH:mm:ss').tz(timezone, true);
	const dayBefore = targetDay < todayWeekday;
	const timeBefore =
		todayWeekday === targetDay &&
		targetTime.isBefore(moment().tz(timezone, true));

	const weeksToAdd = nextWeek || dayBefore || timeBefore ? 1 : 0;

	return targetTime
		.isoWeekday(targetDay)
		.add(weeksToAdd, 'weeks')
		.format('dddd, MMMM Do h:mma z');
}

/**
 * Convert all passed strings to iso string with the given timezone
 * @param times
 * @param timezone
 * @returns
 */
export function convertTimesToIsoStrings(times: Date[]): string[] {
	return times.map((time: Date) => {
		console.log(tz(time).utc().toISOString());
		return new Date(
			Date.UTC(
				time.getFullYear(),
				time.getMonth(),
				time.getDate(),
				time.getHours(),
				time.getMinutes(),
				0,
				0
			)
		).toISOString();
	});
}
